<template>
  <router-view :current-server="currentServer" />
</template>

<script>
export default {
  data() {
    return {
      currentServer: {
        baseUrl: process.env.VUE_APP_API_BASE_URL,
        clientId: process.env.VUE_APP_API_CLIENT_ID,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET,
      }
    }
  },
};
</script>